

body {
    margin: 0;
    /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
    /*'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
    /*sans-serif;*/
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
    font-family: 'Lato';
    src: url("./fonts/Lato-Thin.eot");
    src: local("☺"),url("./fonts/Lato-Thin.woff") format("woff"),url("./fonts/Lato-Thin.ttf") format("truetype"),url("./fonts/Lato-Thin.svg") format("svg");
    font-weight: bold;
    font-style: normal
}

@font-face {
    font-family: 'Lato';
    src: url("./fonts/Lato-Regular.eot");
    src: local("☺"),url("./fonts/Lato-Regular.woff") format("woff"),url("./fonts/Lato-Regular.ttf") format("truetype"),url("./fonts/Lato-Regular.svg") format("svg");
    font-weight: normal;
    font-style: normal
}

@font-face {
    font-family: 'Lato';
    src: url("./fonts/Lato-Bold.eot");
    src: local("☺"),url("./fonts/Lato-Bold.woff") format("woff"),url("./fonts/Lato-Bold.ttf") format("truetype"),url("./fonts/Lato-Bold.svg") format("svg");
    font-weight: bold;
    font-style: normal
}


.react-horizontal-scrolling-menu--item {
    /*margin-left: 50px;*/
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    flex: 2;
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

/*.react-horizontal-scrolling-menu--wrapper {*/
/*    flex-wrap: wrap;*/
/*    justify-content: center;*/
/*}*/

/*.react-horizontal-scrolling-menu--scroll-container {*/
/*    order: 1;*/
/*    flex-basis: 100%;*/
/*    flex-shrink: 0;*/
/*}*/

/*.react-horizontal-scrolling-menu--scroll-container {*/
/*    flex-basis: 100%;*/
/*    flex-shrink: 0;*/
/*}*/

/*!* Change position of container so arrows will be at top/bottom *!*/
/*.react-horizontal-scrolling-menu--scroll-container {*/
/*    order: -1; !* order: 1; for top position *!*/
/*}*/




html {
    color: #000;
    background: #FFF
}


.edit_info_btn {
    position: absolute;
    right: 0px;
    top: -16.5px;
    border: 1px solid #D9D9D9;
    background-color: #ffffff;
    padding: 2px 3px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 200ms ease;
}

.edit_info_btn:hover {
    border: 1px solid #6D6E71;

}
/*.edit_icon_btn {*/
/*    fill: #D9D9D9;*/
/*    color: #D9D9D9 !important;*/
/*    transition: all 200ms ease !important;*/
/*}*/


/*.edit_icon_btn:hover {*/
/*    fill: #6D6E71;*/

/*}*/

.st-total {
    display: none !important;
}

.st-center.st-has-labels.st-inline-share-buttons.st-animated {
    /*-ms-overflow-style: none;  !* IE and Edge *!*/
    /*scrollbar-width: none;*/
    scrollbar-width: thin;
    scrollbar-color: #3DB9E5 #d9d9d9;
    padding: 10px 10px;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    overflow-x: auto;
    white-space: nowrap;
    max-width: 500px;
}

.select_scroll {
    scrollbar-width: thin;
    scrollbar-color: #3DB9E5 #d9d9d9;
    overflow-y: auto;
}

.select_scroll::-webkit-scrollbar {
    width: 5px;
    border-radius: 5px;
    background-color: #d9d9d9;
}

.select_scroll::-webkit-scrollbar-thumb {
    background-color: #3DB9E5;
    width: 5px;
    border-radius: 5px;
    /*outline: 1px solid slategrey;*/
}


.st-center.st-has-labels.st-inline-share-buttons.st-animated::-webkit-scrollbar {
    height: 5px;
    /*display: none;*/
    border-radius: 5px;

    background-color: #d9d9d9;
}

.st-center.st-has-labels.st-inline-share-buttons.st-animated::-webkit-scrollbar-thumb {
    background-color: #3DB9E5;
    height: 5px;
    border-radius: 5px;
    /*outline: 1px solid slategrey;*/
}




/* Hide scrollbar for IE, Edge and Firefox */

.st-btn.st-remove-label {
    display: inline-block !important;
}

 .st-btn[data-network='sharethis'] {
     display: none !important;
 }
/*user page*/

.user_page_ucode_date {
    display: flex;
}

.user_page_date {
flex: 1;
    display: flex;
    justify-content: flex-end;
}
.user_page_datablock {
    justify-content: space-between;
    margin-right: 40px;
}

.user_page_ucode {

    flex: 1;
}

.user_page_fio {
    /*margin-top: 32px;*/
    /*display: flex;*/
   text-align: center;
    /*flex: 1;*/
    /*text-align: center;*/
}

.user_page_mail_tel {
    display: flex;
    margin-top: 32px;
}

.user_page_mail {
    flex: 1;
    /*display: flex;*/
    /*justify-content: flex-end;*/
}

.user_page_header {
    /*flex: 2;*/
    color: #6D6E71;
    /*font-size: 20px;*/
}

.canc_edited_btn {
    color: #3DB9E5;
    display: flex;
    justify-content: center;
    align-items: center;
    /*width: 1;*/
    height: 48px;
    border: 1px solid #3DB9E5;
    background: #FFF;
    border-radius: 4px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;

    position: relative;
    transition: all 0.2s;
}

.canc_edited_btn:hover {
    background: #61CDF3;
    transition: background .3s;
    color: #FFF;
    border: 1px solid #61CDF3;
}

.save_edited_btn {
    transition: background .3s;
    color: #fff;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    border: none;
    height: 48px;
    background: #3db9e5;
    border-radius: 4px;
    padding-right: 20px;
    padding-left: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.save_edited_btn:hover {
    background: #61cdf3;
    transition: background .3s;
}

.user_page_qr_block {
    flex: 1;
}

.wrapper_qr_block {
   width: 100%;
    margin: 0 auto;
    max-width: 500px;
}

.wrap_qr_link {
margin: 10px 0px;
    margin-bottom: 0px;
    /*max-width: 300px;*/
    text-overflow: ellipsis;
    white-space: normal;
    /*overflow: hidden;*/
    cursor: pointer;
    color: #6D6E71;
}

.cl_sess_btn {

}

.wrap_qr_link:hover {
    color: #b6b7b8;
}
.cl_sess_btn {
    color: #6D6E71;
    transition: all .3s;
}
.cl_sess_btn:hover {
    color: #ed6565;
    /*opacity: 0.5;*/
}

/*.qr_link_icon:hover {*/
/*    opacity:#b6b7b8;*/
/*}*/

/*.qr_link {*/
/*  color: #6D6E71;*/
/*}*/

.qr_block {
    /*background: goldenrod;*/
    margin-left: auto;
    margin-right: auto;
    /*padding-top: 50%;*/
    /*padding-bottom: 50%;*/
}

.wrap_qr_img {
    width: 100%;
}

.user_page_tel {
    flex: 1;
    display: flex;
    justify-content: flex-end;
}

.user_page_date_text {
    /*margin*/
}

.user_page_datablock {
    flex: 2;
}

.user_page_qrblock {
    flex: 1;
}






.fake_input {
    background: #FFFFFF;
    /*border: 1px solid #EEEEEE;*/
    box-sizing: border-box;
    border-radius: 4px;
    /*height: 48px;*/
    padding: 0px 16px;
    /*white-space: nowrap;*/
    /*overflow: hidden;*/
    /*text-overflow: ellipsis;*/
    margin-top: 8px;
    height: 100%;
    display: flex;
    align-items: center;
}



body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,code,form,fieldset,legend,input,button,textarea,select,p,blockquote,th,td,a {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: none
}

a {
    color: inherit;
    text-decoration: none;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease
}

a:hover,a:active,a:focus {
    color: inherit;
    outline: none !important;
    text-decoration: none
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

fieldset,img {
    border: 0
}

address,button,caption,cite,code,dfn,em,input,optgroup,option,select,strong,textarea,th,var {
    font: inherit
}

b,strong {
    font-weight: 700
}

del,ins {
    text-decoration: none
}

caption,th {
    text-align: left
}

h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6 {
    font-size: 100%;
    font-weight: normal;
    margin: 0
}

q:before,q:after {
    content: ''
}

abbr,acronym {
    border: 0;
    font-variant: normal
}

sup {
    vertical-align: baseline
}


sub {
    vertical-align: baseline
}

legend {
    color: #000
}

.clearfix:after {
    content: '';
    display: block;
    clear: both
}

label {
    font-weight: 500;
    margin-bottom: 0
}

svg {
    -webkit-transition: fill 0.3s ease;
    -o-transition: fill 0.3s ease;
    transition: fill 0.3s ease
}

input,textarea,button {
    -webkit-appearance: none !important;
    -webkit-border-radius: 0;
    border-radius: 0
}

input[type='number'] {
    -moz-appearance: textfield
}

input::-webkit-outer-spin-button,input::-webkit-inner-spin-button {
    -webkit-appearance: none
}

html {
    overflow-x: hidden
}

body {
    min-height: 100vh;
    font-family: Lato;
    position: relative;
    font-weight: normal;
    background: #F6F7F8;
    font-size: 16px;
    color: #111111
}


.sel_inp_text {
    color:#3db9e5;
}

.wrap_span {
    margin-top:24px;
}



.button_switch_slider_input {
    opacity: 1;
    /*color: rgb(0, 0, 0);*/
    height: 100%;
    border: 1px solid rgb(238, 238, 238);
    padding: 15px 26px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    background: rgb(255, 255, 255);
    transition: color 0s ease 0s, background 0.2s ease 0s;
    flex: 1 1 0%;
    border-radius: 0px 5px 5px 0px;
}

#output {
    width: 170px;
    height: 170px;
}

.save_card_num_btn {
    width: 100%;
    padding: 13px 20px;
    outline: none;
    border: 1px solid #3db9e5;
    color:  #3db9e5;
background: #ffffff;
text-align: center;
cursor: pointer;
border-radius: 5px;
/*marginTop: 24px;*/
transition: all 0.2s;
}

.save_card_num_btn:active {

    color: #ffffff;
background:#3db9e5;

transition: all 0.2s;
}

.banks_wrapper {

    display: flex;
    /*flex: 1 auto;*/
    /*flex: 1 100px;*/
    flex-flow: row wrap;

    cursor: pointer;
    /*display: grid;*/
    /*grid-template-columns: auto auto auto auto auto auto;*/
}

.bank_wrapper {
    border: 1px solid rgb(238, 238, 238);
    padding: 1px;

/*flex: 1 ;*/
/*    flex-flow: row wrap;*/
    cursor: pointer;
    /*display: grid;*/
    /*grid-template-columns: auto auto auto auto auto auto;*/
}

.bank_wrapper:last-child {
   grid-column: 1/4;
    padding: 30px
}


.bank_wrapper:hover {
   background: #3DB9E5;
}


.number_range_input {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    box-sizing: border-box;
    border-radius: 5px 0px 0px 5px;
    height: 48px;
    padding: 12px 16px;
}

.number_range_input:focus {
    border: 1px solid #3DB9E5;
    caret-color: #3DB9E5;
}

.sel_inp
{
    color: #2F2A61;
    font-weight: 600;
    font-size: 16px;
    padding-right: 44px;
    position: relative;
}
.arrow_rep {

    position: absolute;
    right: 10px;
    fill: #3db9e5;
    top: 14px;
    opacity:1;
    transition: all 0.1s;
}

.que_modal {

    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    box-sizing: border-box;
    box-shadow: 0px 4px 8px rgb(0 0 0 / 10%);
    border-radius: 4px;
    padding: 8px 10px;
    font-size: 12px;
    /*max-width: 191px;*/
    line-height: 15px;
    color: #111;
}

.range_thumb {
    outline: none !important;
    position: absolute;
    cursor: pointer;
    top: 3px;
    min-width: 34px;
    margin-left: -26px;
    /*border: "1px solid";*/
    padding: 0 8px;
    font-weight: 500 !important;
    height: 24px;
    z-index: 2;
    background: #3db9e5;
    -webkit-border-radius: 30px;
    border-radius: 30px;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    /*font-weight: bold;*/
    color: #ffffff;
}
.range_thumb.double_slider_thumb {
    min-width: 50px;
}
.modal_double_range {
    background: #ffffff;
    border: 1px solid #eeeeee;
    box-sizing: border-box;
    box-shadow: 0px 4px 8px rgb(0 0 0 / 10%);
    border-radius: 4px;
    padding: 8px 10px;
    font-size: 12px;
    max-width: 191px;
    line-height: 15px;
    color: #111;

}


/*.modal_double_range::after, .modal_double_range::before {*/
/*    width: 10px;*/
/*    height: 10px;*/
/*    position: absolute;*/
/*    content: '';*/
/*    top: -5px;*/
/*    left: 50%;*/
/*    background: #fff;*/
/*    z-index: -1;*/
/*    transform: rotate(*/
/*            45deg );*/
/*     box-shadow: 5px 10px 14px rgb(0 0 0 / 6%);*/
/*}*/


/*.modal_double_range::before {*/
/*    z-index: 1015;*/
/*    box-shadow: none;*/
/*}*/


.arrow_rep_up {
    /* display: none; */
}
.arrow_rep_d {

}

.option_thema {
    padding-top: 14px;
    cursor: pointer;
    padding: 7px 20px;
    border-top: 1px solid #f4f4f4;
}

.option_thema:hover {
    background-color: #3db9e5;
    color: white;
}

.wrap_options {
/*position: relative;*/
    z-index: 1000;
    background: white;
    width: 100%;
    box-shadow: 0 -2px 20px rgb(0 0 0 / 20%);
    padding: 10px 0;
    transition: all 0.4s;
}

.wrap_options__ {
    position: absolute;
    z-index: 1000;
    width: 100%;
    top: 50px;
    right: 0px;
    transition: all 0.4s;
}

.wrap_options::after, .wrap_options::before {
    width: 10px;
    height: 10px;
    position: absolute;
    content: '';
    top: -5px;
    left: 20px;
    background: #fff;
    z-index: -1;
    transform: rotate(
            45deg
    );
    /* box-shadow: 5px 10px 14px rgb(0 0 0 / 6%); */
}

.wrap_options::before {
    z-index: 1015;
    box-shadow: none;
}

.options_themes {
    display: flex;

    flex-direction: column;
    color: #2F2A61;

    font-weight: 600;
    font-size: 14px;
    overflow-y: auto;
    /* max-height: 168px; */
    transition: all 0.4s;
    /*-ms-overflow-style: none;  !* IE and Edge *!*/
    /*scrollbar-width: none;*/

}

.options_themes::-webkit-scrollbar {
    /*display: none;*/
}



/* Hide scrollbar for IE, Edge and Firefox */

.react-tel-input .invalid-number-message{
    font-family: Lato;
}


.react-tel-input .country-list .country {
    font-family: Lato !important;
}

.react-tel-input .country-list .country:hover {
    background-color: #EEEEEE !important;
}

.country-list {
    position: relative !important;
}
.selected-flag.open::after {
    content: '';
    width: 10px;
    height: 10px;
    background: white;
    bottom: -16px;
    position: absolute;
    transform: rotate(45deg);
    z-index: 19999;
    border-top: 1px solid #3db9e5;
    border-left: 1px solid #3db9e5;
}

.flag-dropdown.open {
    border-right: none !important;
}
.wrap_select {
    position: relative;
/*min-height: 48px;*/
    border: 1px solid #EEEEEE;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 16px;
    box-sizing: border-box;
    /* padding-right: 44px; */

}

.wrap_select_manipulating {
    position: relative;
    min-height: 48px;
    border: 1px solid #EEEEEE;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 16px;
    box-sizing: border-box;
    /* padding-right: 44px; */

}


.wrap_opti {
    position: absolute;
    z-index: 1000;
    background: #fff;
    width: 100%;
    top: 50px;
    right: 0;
    box-shadow: 0 -2px 20px rgb(0 0 0 / 20%);
    padding: 10px 0;
    transition: all .4s;
}

.wrap_opti::after, .wrap_opti::before {
    width: 10px;
    height: 10px;
    position: absolute;
    content: '';
    top: -5px;
    left: 20px;
    background: #fff;
    z-index: -1;
    transform: rotate(
            45deg
    );
    /* box-shadow: 5px 10px 14px rgb(0 0 0 / 6%); */
}

.wrap_opti::before {
    z-index: 1015;
    box-shadow: none;
}
.wrap_sel_block {
    width: 100%;
    cursor: pointer;
    /* height: 56px; */
    /* margin-top: 24px; */
}

.disabled_bg {
    background: #eeeeee;
}


.theme_inp {
    margin-top: 20px;
    background: linear-gradient(0deg, #DFE6F6, #DFE6F6);
    box-shadow: inset -4px -5px 17px rgba(255, 255, 255, 0.699055), inset 6px 8px 19px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    border: none;
    padding-left: 16px;

    padding-top: 14px;
    padding-bottom: 14px;
    color: #9d9fbe;
    font-size: 24px;

}

input[name="radiobStep1"]+label {
    min-width: 192px
}

input[name="radiobStep2"]+label {
    min-width: 152px
}

input[name="radiob2Step2"]+label {
    min-width: 278px
}

input[name="radiobStep4"]+label {
    min-width: 101px
}

.container {
    width: 1024px;
    margin: 0 auto
}

.header {
    height: 72px;
    background: #FFFFFF;
    -webkit-box-shadow: 0px 0px 16px rgba(0,0,0,0.06);
    box-shadow: 0px 0px 16px rgba(0,0,0,0.06)
}

.header-wr {
    max-width: 1440px;
    padding: 0 100px 0 102px;
    margin: 0 auto;
    height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.header-wr-left,.header-wr-right {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.header-wr-left-text {
    margin-left: 18.18px;
    max-width: 355px;
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    color: #6D6E71
}

.header-wr-right-flag {
    margin-right: 9px
}

.header-wr-right-num {
    color: #6D6E71;
    margin-top: -3px
}

.header-wr-right-num b {
    color: #000
}

.header-wr-right-num a {
    color: #000;
    font-weight: bold
}

.leftImg {
    position: absolute;
    top: 204.22px;
    left: 0px
}

.left_bottom_Img_bg {
    position: absolute;
    bottom: 0px;
    left: 0px
}

.bottom_Img_bg {
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    margin: auto
}

.right_bottom_Img_bg {
    position: absolute;
    bottom: 0px;
    right: 0px
}

.rightImg {
    position: absolute;
    top: 184.69px;
    right: 0px
}

.stepline-wr {
    height: 96px;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.stepline-wr:before {
    position: absolute;
    content: '';
    height: 2px;
    width: 30vw;
    background: #3DB9E5;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto
}

.stepline-wr:after {
    position: absolute;
    content: '';
    height: 2px;
    width: 30vw;
    background: #D9D9D9;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto
}

.container.stepline {
    width: 1000px
}

.stepline {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    z-index: 2;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.stepline-item {
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    width: 14.3%;
    height: 12px;
    background: #F6F7F8;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    position: relative
}

.stepline-item:before {
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    position: absolute;
    content: '';
    width: -webkit-calc(100% - 12px);
    width: calc(100% - 12px);
    height: 2px;
    left: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    background: #D9D9D9
}

.stepline-item-circle {
    width: 12px;
    height: 12px;
    background: #FFFFFF;
    border: 2px solid #BABABA;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.stepline-item:first-child .stepline-item-circle {
    width: 16px;
    height: 16px;
    border: 2px solid #3DB9E5;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.stepline-item:first-child .stepline-item-circle-dot {
    width: 4px;
    height: 4px;
    background: #3DB9E5;
    -webkit-border-radius: 2px;
    border-radius: 2px
}

.stepline-item:first-child {
    width: 16px;
    height: 16px
}

.stepline-item:first-child:before {
    content: none
}

.stepline-item.active:first-child {
    width: 12px;
    height: 12px
}

.stepline-item.active {
    width: 14.3%;
    height: 12px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s
}

.stepline-item.active:before {
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    background: #3DB9E5
}

.stepline-item.active .stepline-item-circle {
    z-index: 2;
    width: 12px;
    height: 12px;
    background: #3DB9E5;
    border: 2px solid #3DB9E5;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.stepline-item.active .stepline-item-circle-dot {
    width: 4px;
    height: 4px;
    background: #3DB9E5;
    -webkit-border-radius: 2px;
    border-radius: 2px
}

.stepline-item.curr {
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    width: 14.3%;
    height: 16px
}

.stepline-item.curr:before {
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    background: #3DB9E5
}

.stepline-item.curr .stepline-item-circle {
    z-index: 2;
    width: 16px;
    height: 16px;
    border: 2px solid #3DB9E5;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.stepline-item.curr .stepline-item-circle-dot {
    width: 4px;
    height: 4px;
    background: #3DB9E5;
    -webkit-border-radius: 2px;
    border-radius: 2px
}

.wrapper.Steps {
    padding-bottom: 40px
}

.step.active {
    display: block
}

.step {
    position: relative;
    display: none;
    z-index: 2;
    background: #FFFFFF;
    -webkit-box-shadow: 0px 0px 16px rgba(0,0,0,0.06);
    box-shadow: 0px 0px 16px rgba(0,0,0,0.06);
    -webkit-border-radius: 4px;
    border-radius: 4px;
    padding: 36px 40px 40px
}

.step-toptext {
    line-height: 24px;
    font-size: 16px;
    color: #111111;
    margin-bottom: 32px
}

.step-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 32px
}

.step-row-label {
    font-size: 14px;
    line-height: 21px;
    color: #6D6E71;
    margin-bottom: 10px
}

.step-row-label sub {
    color: #3DB9E5
}

.step-row-label .mob {
    display: none
}

.step-row-field {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative
}

.step-row-field .underRangeText {
    position: absolute;
    bottom: -20px;
    right: 2px;
    font-size: 12px;
    color: #6D6E71
}

.step-row-field .underRangeText sub {
    color: #3DB9E5
}

.step-row-field label {
    font-size: 14px;
    line-height: 21px;
    color: #6D6E71;
    margin-bottom: 10px
}

.step-row-field label sub {
    color: #3DB9E5
}

.step-row-field input {
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    height: 48px;
    padding: 12px 16px
}

.step-row-field textarea {
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    /*height: 48px;*/
    padding: 12px 16px;
    resize: none;
}

.step-row-field textarea:focus {
    border: 1px solid #3DB9E5;
    caret-color: #3DB9E5;
}

.step-row-checks {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    border-radius: 10px;
}

.step-row-checks label {
    cursor: pointer;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    padding: 0 25px;
    height: 48px
}

.mobilePhone,.mobLineStatus,.question.mob {
    display: none
}

.bottomAbText.active,.underRangeText.active {
    opacity: 1;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-shadow: 0px 4px 8px rgba(0,0,0,0.1);
    box-shadow: 0px 4px 8px rgba(0,0,0,0.1);
    -webkit-border-radius: 4px;
    border-radius: 4px;
    padding: 8px 10px;
    font-size: 12px;
    max-width: 191px;
    line-height: 15px;
    color: #111
}

.step-row-checks label:nth-child(2) {
    -webkit-transition: background, border .5s;
    -o-transition: background, border .5s;
    transition: background, border .5s;
    position: relative;
    -webkit-border-top-right-radius: 0px;
    border-top-right-radius: 0px;
    -webkit-border-bottom-right-radius: 0px;
    border-bottom-right-radius: 0px;
    /* border-right: 0 */
}

.step-row-checks label:last-child {
    -webkit-transition: background, border .5s;
    -o-transition: background, border .5s;
    transition: background, border .5s;
    position: relative;
    -webkit-border-top-left-radius: 0px;
    border-top-left-radius: 0px;
    -webkit-border-bottom-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: 0
}

.step-row-checks input:checked+label {
    -webkit-transition: background, border .5s;
    -o-transition: background, border .5s;
    transition: background, border .5s;
    background: #3DB9E5;
    color: #fff;
    border: 1px solid #3DB9E5;
    padding-left: 26px;
    padding-right: 26px
}

.step-row-checks input:checked+label span {
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
    position: relative;
    padding-left: 30px
}

.hide_scroll::-webkit-scrollbar {
    display: none;
}

.MuiDialog-root{
    z-index: 1654646484 !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide_scroll_general {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.step-row-checks input:checked+label span:before {
    position: absolute;
    content: '';
    width: 13.37px;
    height: 10.04px;
    left: 0px;
    bottom: 0;
    top: 0;
    margin: auto;
    background: url("./images/Vector.png") center no-repeat;
    background-size: cover
}

.dataP {
    position: relative
}

.dataP:after {
     position: absolute;
    content: '';
    width: 18px;
    height: 20px;
    background: #fff url("./images/dataP.png") center no-repeat;
    bottom: 14px;
    right: 20px ;
}

#sf1 {
    /*width: 641px*/
}

#sf2 {
    width: 263px
}

#sf3 {
    max-width: 100%
}

.step-row.w100,.step-row.w100 .step-row-field,.step-row.w100 .step-row-field input {
    width: 100%
}

.step-row-field input:focus {
    border: 1px solid #3DB9E5;
    caret-color: #3DB9E5
}

.step-row.w50 {
    width: 100%
}

.step-row.w50 .step-row-field {
    width: 47%
}

.react-date-picker__inputGroup input {
    min-width: 0.54em;
    height: 100%;
    position: relative;
    padding: 0 1px;
    border: 0;
    background: none;
    font: inherit;
    box-sizing: content-box;
}

.react-date-picker__inputGroup .react-date-picker__inputGroup__input--hasLeadingZero {
    margin-left: -0.54em;
    padding-left: calc(1px + 0.54em);


}



.react-date-picker__wrapper .react-date-picker__inputGroup {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 16px;
}

.react-date-picker .react-date-picker__wrapper {
    border: 1px solid #EEEEEE;
    /* margin-left: 0 !important;
    margin-left: -0.54em !important;
    padding-left: calc(1px + 0.54em) !important; */
}

.step-row.radios {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column
}

.step-row.radios.w50 {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    position: relative
}

.step-row.radios.w50 .step-row-checks input {
    display: none
}

.step-row.radios.w50 .step-row-checks label {
    min-width: 50%
}

.step-row.radios.w50 .bottomAbText {
    position: absolute;
    font-size: 12px;
    line-height: 18px;
    color: #6D6E71;
    left: 0;
    bottom: -12px
}

.step-row.radios.w50 .bottomAbText sub {
    color: #3DB9E5
}

.bottomAbText.desct {
    opacity: 0;
    z-index: -1
}

.bottomAbText.desct.active {
    opacity: 1;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-shadow: 0px 4px 8px rgba(0,0,0,0.1);
    box-shadow: 0px 4px 8px rgba(0,0,0,0.1);
    -webkit-border-radius: 4px;
    border-radius: 4px;
    padding: 8px 10px;
    font-size: 12px;
    max-width: 191px;
    line-height: 15px;
    color: #111;
    z-index: 1;
    bottom: 30px
}

.step-row.last {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin: 0
}

.step-row.text {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column
}

.step-row.text .textR_line {
    position: relative
}

.step-row.text .textR_line span {
    position: relative;
    background: #FFFFFF;
    z-index: 2;
    padding-right: 8px;
    line-height: 24px;
    font-size: 16px
}

.step-row.text .textR_line:before {
    position: absolute;
    content: '';
    top: 12px;
    height: 1px;
    width: 100%;
    background: #D9D9D9;
    -webkit-border-radius: 1px;
    border-radius: 1px
}

.step-row.text .text_under_textR_line {
    margin-top: 8px;
    color: #6D6E71;
    font-size: 12px
}

.step-row.text .text_under_textR_line span {
    color: #3DB9E5
}

.prevBtn {
    color: #3DB9E5;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: 126px;
    height: 48px;
    border: 1px solid #3DB9E5;
    background: #FFF;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    padding-left: 20px;
    cursor: pointer;
    margin-right: 40px;
    position: relative;
    transition: all 0.2s;
}

.prevBtn:before {
    position: absolute;
    content: '';
    width: 15.33px;
    height: 13.67px;
    background: url("./images/arrow_blue.png") center no-repeat;
    transform: rotate(180deg);
    background-size: cover;
    left: 26px;
    top: 18px;
    transition: all 0.2s;
}

.prevBtn:hover {
    background: #61CDF3;
    -webkit-transition: background .3s;
    -o-transition: background .3s;
    transition: background .3s;
    color: #FFF;
    border: 1px solid #61CDF3
}

.prevBtn:hover:before {
    -webkit-transform: rotateZ(180deg);
    -ms-transform: rotate(180deg);
    transform: rotateZ(180deg);
    background: url("./images/arrow_white.png") center no-repeat;
    transform: rotate(180deg);
    background-size: cover;
    left: 20px;
    transition: all 0.4s;
}

.nextBtnWr {
    width: 126px;
    height: 48px;
    position: relative;
    transition: all 0.2s;
}

.nextBtnWr:after {
    position: absolute;
    content: '';
    width: 15.33px;
    height: 13.67px;
    background: url("./images/arrow_white.png") center no-repeat;
    background-size: cover;
    right: 26px;
    top: 19.3px;
    transition: all 0.2s;
}

.nextBtnWr:hover:after {
    position: absolute;
    content: '';
    width: 15.33px;
    height: 13.67px;
    background: url("./images/arrow_white.png") center no-repeat;
    background-size: cover;
    right: 20px;
    top: 19.3px;
    transition: all 0.4s;
}

.nextBtn:hover {
    background: #61CDF3;
    -webkit-transition: background .3s;
    -o-transition: background .3s;
    transition: background .3s
}

.nextBtn {
    -webkit-transition: background .3s;
    -o-transition: background .3s;
    transition: background .3s;
    color: #fff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border: none;
    width: 100%;
    height: 100%;
    background: #3DB9E5;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    padding-right: 20px;
    cursor: pointer
}

.rangeWr {
    height: 48px;
    width: 100%;
    background: #F6F7F8;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    display: flex;
    justify-content: space-around;
}

.rangeContainer {
    /*padding-left: 20px;*/
    /*padding-right: 20px;*/
    /*max-width: 335px;*/
    /* margin: 0px 55px auto */
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
}

.w100 .rangeContainer {
    /* max-width: 100%;
    margin: 0px 70px 0 50px */
    /*max-width: 98%;*/
}

.rangeContainer .irs--flat .irs-min {
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    left: -32px;
    background: none;
    top: 13px;
    font-size: 14px;
    line-height: 21px;
    color: #6D6E71;
    visibility: visible !important
}

.rangeContainer .irs--flat .irs-min.sm {
    font-size: 9px;
    left: -42px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s
}

.rangeContainer .irs--flat .irs-max {
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    right: -67px;
    text-align: center;
    width: 60px;
    background: none;
    top: 13px;
    font-size: 14px;
    line-height: 21px;
    color: #6D6E71;
    visibility: visible !important
}

.rangeContainer .irs--flat .irs-max.sm {
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    font-size: 9px;
    right: -77px
}

.rangeContainer .irs--flat .irs-line {
    top: 22px;
    height: 4px;
    background: #D9D9D9;
    -webkit-border-radius: 16px;
    border-radius: 16px
}

.rangeContainer .irs--flat .irs-bar {
    top: 22px;
    height: 4px;
    background-color: #86D9F6;
    -webkit-border-radius: 16px;
    border-radius: 16px
}

.rangeContainer .irs--flat .irs-handle {
    display: none
}

.rangeContainer .irs--flat .irs-single {
    cursor: pointer;
    top: 12px;
    min-width: 34px;
    padding: 0 8px;
    font-weight: 500 !important;
    height: 24px;
    z-index: 2;
    background: #3DB9E5;
    -webkit-border-radius: 30px;
    border-radius: 30px;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    color: #FFFFFF
}

.rangeContainer .irs--flat .irs-single:before {
    display: none
}

.step-row-field .nselect.nsOrange._checked .nselect__head {
    color: #111111
}

.step-row-field .nselect .nselect__head SPAN {
    font-size: 16px
}

.step-row-field .nselect.nsOrange .nselect__list LI._active SPAN {
    color: #3DB9E5
}

.step-row-field .nselect.nsOrange .nselect__head {
    background: #FFFFFF;
    width: 100%;
    border: 1px solid #EEEEEE;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    height: 48px;
    padding: 12px 16px
}

.step-row-field .nselect.nsOrange .nselect__head:after {
    width: 16px;
    height: 16px;
    margin: 0;
    border-width: 2px 3px 0 3px;
    border-color: #fff;
    background: url("./images/selectArr.png") center no-repeat
}

.step-row-field .nselect.nsOrange .nselect__inner {
    text-align: left;
    top: 50px;
    left: 0;
    z-index: 15;
    width: 100%
}

.tg {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    margin-top: 8px
}

.tg thead tr th {
    font-size: 14px;
    line-height: 21px;
    color: #6D6E71;
    font-family: 'Lato'
}

.table1 .tg thead tr th:nth-child(1) {
    width: 140px
}

.table1 .tg thead tr th:nth-child(2) {
    width: 585px
}

.table1 .tg thead tr th:nth-child(3) {
    width: 110px
}

.table1 .tg thead tr th:nth-child(4) {
    width: 110px;
    padding: 9px 15px
}

.table1 .tg thead tr th:first-child,.table2 .tg thead tr th:first-child {
    text-align: center
}

.table2 .tg thead tr th:first-child {
    width: 48px
}

.table1 .tg thead tr th:last-child,.table2 .tg tbody tr td:first-child {
    text-align: center
}

.tg tbody tr td {
    font-size: 16px;
    line-height: 24px;
    font-family: 'Lato'
}

.table1 .tg tbody tr td[rowspan="2"]:first-child {
    text-align: center
}

.table1 .tg tbody tr td[rowspan="2"]:first-child+.tg-0pky {
    height: 48px
}

tr.wr,tr.inside {
    background: #F6F7F8;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s
}

tr.wr.active {
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    background: #fff
}

tr.inside.active {
    display: none
}

tr.wr td {
    position: relative
}

tr.wr .arr {
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    width: 16px;
    height: 10px;
    position: absolute;
    top: 18.27px;
    right: 16px;
    background: url("./images/tableArr.png") no-repeat
}

tr.wr .arr.active {
    -webkit-transform: rotateZ(180deg);
    -ms-transform: rotate(180deg);
    transform: rotateZ(180deg);
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s
}

.table2 .tg tbody tr.inside td[colspan="2"] {
    text-align: left;
    padding-left: 88px
}

.table1 .tg tbody tr td:nth-child(3),.tg tbody tr td:nth-child(4) {
    padding: 88px 16px;
    text-align: center
}

.question {
    margin-bottom: -4px;
    margin-left: 5px
}

.table1 .tg td {
    padding: 24px 16px
}

.table2 .tg td {
    padding: 12px 16px
}

.tg td {
    border-color: #EEEEEE;
    border-style: solid;
    border-width: 1px;
    font-family: Arial, sans-serif;
    font-size: 14px;
    overflow: hidden;
    word-break: normal
}

.tg th {
    border-color: #EEEEEE;
    border-style: solid;
    border-width: 1px;
    font-family: Arial, sans-serif;
    font-size: 14px;
    font-weight: normal;
    overflow: hidden;
    padding: 9px 16px;
    word-break: normal
}

.tg .tg-0pky {
    border-color: #EEEEEE;
    text-align: left;
    vertical-align: top
}

.tableTotal {
    font-size: 20px;
    line-height: 30px;
    color: #6D6E71;
    text-align: right;
    width: 100%
}

.tableTotal span {
    color: #111111;
    font-weight: bold
}

.tabledescr {
    font-size: 12px;
    line-height: 18px;
    color: #373737
}

.tabledescr span {
    color: #111111;
    font-weight: bold
}

.step-row.s7bl .arr.active {
    -webkit-transform: rotateZ(180deg);
    -ms-transform: rotate(180deg);
    transform: rotateZ(180deg);
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s
}

.step-row.s7bl {
    position: relative;
    padding: 12px 16px;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column
}

.step-row.s7bl .arr {
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    width: 16px;
    height: 10px;
    position: absolute;
    top: 20px;
    right: 16px;
    background: url("./images/tableArr.png") no-repeat
}

.step-row.s7bl .botBlock {
    border-top: 1px solid #EEEEEE;
    -webkit-border-radius: 1px;
    border-radius: 1px;
    padding-top: 12px;
    margin-top: 12px;
    position: relative;
    text-align: center
}

.step-row.s7bl .botBlock:before {
    position: absolute;
    top: 0;
    width: 100%;
    height: 1px;
    background: #EEEEEE;
    -webkit-border-radius: 1px;
    border-radius: 1px
}

.step-row.s7bl .botBlock .els {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 10px
}

.step-row.s7bl .botBlock .els:last-child {
    margin-bottom: 2px
}

.step-row.s7bl .botBlock .els .el {
    margin-right: 90px
}

.step-row.s7bl .botBlock.tl {
    text-align: left;
    padding-bottom: 2px;
    font-size: 14px
}

.botBlock.active {
    display: none
}

.step-row.medias {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.step-row.medias .media {
    margin-right: 69px
}

.step-row.medias .media img {
    margin-right: 7px;
    margin-bottom: -2px
}

.step-row.medias .media:last-child {
    margin: 0
}

.botBlock img {
    max-width: 100%
}

.step-row.medias.l {
    margin-bottom: 40px
}

.tg-0pky .underRangeText {
    position: absolute;
    opacity: 0
}

.tg-0pky .underRangeText.active {
    margin-top: -25px;
    opacity: 1
}

.step-toptext .dect {
    display: block
}

.step-toptext .mob {
    display: none
}

.step-toptext h1 {
    font-weight: bold;
    text-align: center;
    font-size: 18px
}

.step-row-checks input[type="radio"] {
    position: absolute;
    opacity: 0;
    left: -500px
}

.r2_11 {
    display: none
}

.tooltip .rangeWr .rangeContainer .irs .irs-min {
    overflow: visible;
    overflow: visible;
    top: 5px;
    margin-left: -7px
}

.tooltip .rangeWr .rangeContainer .irs .irs-min:before {
    position: absolute;
    content: 'l/min';
    left: -1px;
    bottom: -12px
}

.tooltip .rangeWr .rangeContainer .irs .irs-max {
    overflow: visible;
    overflow: visible;
    top: 5px;
    margin-right: -7px
}

.tooltip .rangeWr .rangeContainer .irs .irs-max:before {
    position: absolute;
    content: 'l/min';
    right: 17px;
    bottom: -12px
}

.tooltip2 .rangeWr .rangeContainer .irs .irs-min {
    overflow: visible;
    overflow: visible;
    top: 5px;
    margin-left: -7px
}

.tooltip2 .rangeWr .rangeContainer .irs .irs-min:before {
    position: absolute;
    content: 'm3';
    left: -1px;
    bottom: -12px
}

.tooltip2 .rangeWr .rangeContainer .irs .irs-max {
    overflow: visible;
    overflow: visible;
    top: 5px;
    margin-right: -7px
}

.tooltip2 .rangeWr .rangeContainer .irs .irs-max:before {
    position: absolute;
    content: 'm3';
    right: 22px;
    bottom: -12px
}

.question.mob.desct {
    display: inline-block
}

.underRangeText.desct {
    z-index: -9;
    opacity: 0
}



.underRangeText.desct.active {
    z-index: 1;
    opacity: 1;
    top: 0;
    bottom: 0;
    margin-left: 195px
}
.range_thumb {
    outline: none !important;
    position: absolute;
    cursor: pointer;
    top: 4px;
    min-width: 34px;
    margin-left: -26px;
    padding: 0 8px;
    font-weight: 500 !important;
    height: 24px;
    z-index: 2;
    background: #3DB9E5;
    -webkit-border-radius: 30px;
    border-radius: 30px;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    /*font-weight: bold;*/
    color: #FFFFFF;
}


@media (max-width: 500px) {
    .wrap_qr_img {
       /*padding: 16px;*/

    }
    .modal_qr_link {
        /*margin-left: 16px;*/
        /*margin-right: 16px;*/
    }
}


@media (max-width: 1024px) {

    .st-center.st-has-labels.st-inline-share-buttons.st-animated {
        /*-ms-overflow-style: none;  !* IE and Edge *!*/
        /*scrollbar-width: none;*/
    }
    .st-center.st-has-labels.st-inline-share-buttons.st-animated::-webkit-scrollbar {
        /*display: none;*/
    }


    .step-row.radios.w50 .step-row-field:last-child {
        margin-top:24px
    }

    /*.range_thumb {*/

    /*    top: 11px;*/

    /*}*/

    .wrap_rad_btn{
        width: 100%;
    }

    .container {
        width: 100%;
        padding: 24px 16px 32px
    }

    .step-toptext h1 {
        font-weight: bold;
        text-align: left;
        font-size: 18px
    }

    .step-row-field,.step-row.w50 .step-row-field,.step-row.w50,.step-row.radios.w50 {
        width: 100%;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .step-row-field input,#sf1,#sf2 {
        width: 100%
    }

    .phone-input:focus {
        border: 1px solid #3DB9E5;
        caret-color: #3DB9E5;
    }


    .step-row-field label,.step-row.text,.step-row.table1,.step-row.table2,.tableTotal,.tabledescr,.step-row.s7bl {
        margin-top: 24px
    }

    .step-row.last {
        margin-top: 32px;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row
    }

    .media,.step-row.medias .media:last-child {
        margin: 16px 0 0
    }

    .step-row.medias .media:first-child {
        margin: 24px 0 0
    }

    .step-row.s7bl .botBlock .els .el {
        margin-right: 0;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%
    }

    .step-row.s7bl .botBlock .els {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }

    .step-row.s7bl .botBlock .els .el {
        margin-bottom: 10px
    }

    .step-row.s7bl .botBlock .els {
        margin-bottom: 0
    }

    .step-toptext {
        margin-bottom: 0
    }

    .step-row-checks input+label {
        padding-left: 15px;
        padding-right: 15px
    }

    .step-row-checks input+label span {
        font-size: 12px
    }

    .step-row-checks input:checked+label {
        padding-left: 15px;
        padding-right: 15px
    }

    .step-row-checks input:checked+label span {
        font-size: 12px
    }

    .step-row {
        margin-bottom: 0px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .stepline-wr,.left_bottom_Img_bg,.bottom_Img_bg,.right_bottom_Img_bg,.rightImg,.leftImg {
        display: none
    }

    .wrapper.Steps {
        padding-bottom: 0
    }

    .prevBtn,.nextBtnWr {
        width: 48%
    }

    .step-row-label+.step-row-checks {
        margin-top: 0
    }

    .rangeContainer {
        max-width: 98%;
        /* margin: 0px 50px auto */
    }

    .rangeContainer .irs--flat .irs-max {
        right: -80px
    }

    .w100 .rangeContainer .irs--flat .irs-max {
        right: -67px
    }

    .step-row-checks label {
        width: 100% !important;
        min-width: 50% !important
    }

    .header-wr {
        padding: 0 16px 0 16px
    }

    .header-wr-left-text span,.header-wr-right-flag,.header-wr-right-num {
        display: none
    }

    .mobilePhone {
        display: block;
        width: 36px;
        height: 36px;
        background: #3DB9E5;
        -webkit-border-radius: 4px;
        border-radius: 4px;
        position: relative
    }

    .mobilePhone:before {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        margin: auto;
        background: url("./images/mobPh.png") no-repeat;
        background-size: cover;
    }

    .mobLineStatus {
        display: block;
        background: #D9D9D9;
        width: 100%;
        height: 4px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-transition: .3s;
        -o-transition: .3s;
        transition: .3s
    }

    .mobLineStatus-item {
        width: 12.5%;
        -webkit-transition: .3s;
        -o-transition: .3s;
        transition: .3s
    }

    .mobLineStatus-item.active {
        -webkit-transition: .3s;
        -o-transition: .3s;
        transition: .3s;
        background: #3DB9E5
    }

    .tg tbody tr td img {
        width: 33px
    }

    .tg thead tr th {
        font-size: 12px;
        padding: 6px 9px
    }

    .table1 .tg thead tr th:nth-child(4) {
        padding: 6px 7px;
        min-width: 90px
    }

    .table1 .tg thead tr th:nth-child(3) {
        min-width: 77px
    }

    .table1 .tg thead tr td:nth-child(4) {
        padding: 41px 7px
    }

    .table1 .tg tbody tr td:nth-child(3) {
        padding: 41px 8px
    }

    .tg tbody tr td:nth-child(4) {
        padding: 41px 12px
    }

    .table1 .tg td {
        font-size: 12px;
        padding: 6px 7px 8px 7px
    }

    .table1 .tg tbody tr td[rowspan="2"]:first-child {
        padding: 25px 13px 25px 12px
    }

    .step.active.step5 .step-row.radios,.step.active.step5 .step-row.radios .step-row-checks {
        margin-top: 0
    }

    .tableTotal {
        font-size: 16px
    }

    .nextBtnWr.first {
        width: 100%
    }

    .nextBtnWr.first:after {
        right: 135px
    }

    .container.step.step2.active,.container.step.step3.active {
        padding-top: 0
    }

    .container.step.step4.active .step-row.text,.container.step.step8.active .step-row.text {
        margin-top: 0
    }

    .bottomAbText,.underRangeText {
        opacity: 0
    }

    .step-row-label+.step-row-checks label {
        margin-top: 0
    }

    .step-row.radios.w50.topP .bottomAbText.active {
        left: 150px !important;
        top: -60px
    }

    .step-row.radios.w50 .bottomAbText.active {
        left: 67px;
        top: 0;
        bottom: inherit;
        z-index: 3
    }

    .step-row-label .mob {
        display: block
    }

    .step-row-label .desct {
        display: none
    }

    .step-row-field .underRangeText.active {
        left: 77px;
        top: 20px;
        bottom: inherit;
        z-index: 3
    }

    .question.mob {
        display: inline-block;
        margin-bottom: -5px;
        margin-left: 0px
    }

    .table2 .tg tbody tr td img {
        width: 17px
    }

    .step-row.s7bl .botBlock.tl {
        line-height: 21px
    }

    .table2 .tg tbody tr td {
        font-size: 12px;
        padding: 7px 8px 7px 8px
    }

    .table2 .tg thead tr th:nth-child(3) {
        padding: 6px 7px;
        min-width: 91px
    }

    .table2 .tg tbody tr.inside td[colspan="2"] {
        padding-left: 57px
    }

    .step.active {
        min-height: 88vh
    }

    input[name="radiobStep1"]+label {
        min-width: 50%
    }

    .step-toptext .dect {
        display: none
    }

    .step-toptext .mob {
        display: block
    }
}

@media (max-width: 360px) {
    .step-toptext h1 {
        font-size:16px
    }

    .nextBtnWr.first:after {
        right: 110px
    }
}

/*@media (min-height: 1160px) {*/
/*    .range_thumb {*/

/*        top: 4px;*/

/*    }*/
/*}*/

/*# sourceMappingURL=maps/style.css.map*/




















                        body {
    margin: 0;
    /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
    /*'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
    /*sans-serif;*/
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.l_min_range {
    /*margin-right: 32px;*/
    /*margin-left: 10px;*/
}

.r_max_range {
    /*margin-left: 13px;*/
    /*right: 10px;*/
}




.react-date-picker__inputGroup input {
    min-width: 0.54em;
    height: 100%;
    position: relative;
    padding: 0 1px;
    border: 0;
    background: none;
    font: inherit;
    box-sizing: content-box;
}

.react-date-picker__inputGroup .react-date-picker__inputGroup__input--hasLeadingZero {
    margin-left: -0.54em;
    padding-left: calc(1px + 0.54em);


}



.react-date-picker__wrapper .react-date-picker__inputGroup {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 16px;
}

.react-date-picker .react-date-picker__wrapper {
    border: 1px solid #EEEEEE;
    /* margin-left: 0 !important;
    margin-left: -0.54em !important;
    padding-left: calc(1px + 0.54em) !important; */
}