.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.react-date-picker__inputGroup input {
  min-width: 0.54em;
  height: 100%;
  position: relative;
  padding: 0 1px;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
}

.react-date-picker__inputGroup .react-date-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
    padding-left: calc(1px + 0.54em);


}



.react-date-picker__wrapper .react-date-picker__inputGroup {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 16px;
}

.react-date-picker .react-date-picker__wrapper {
  border: 1px solid #EEEEEE;
  /* margin-left: 0 !important;
  margin-left: -0.54em !important;
  padding-left: calc(1px + 0.54em) !important; */
}

